import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { BsArrowRight } from '@react-icons/all-files/bs/BsArrowRight';
import { useSpring, animated } from 'react-spring';

import Image from './Image';

import { media } from './theme';
import { useViewport } from './hooks';

const GalleryPainting = ({ slug, images, title, info, gallerySize }) => {
  // console.log(images[0]);

  const { width } = useViewport();
  const itemSize =
    width > 768
      ? gallerySize.width * 0.33333 - 30
      : gallerySize.width * 0.5 - 10;

  const getBoxSize = () => {
    const aspect = images[0].asset.fluid.aspectRatio;
    // aspect = width / height
    // < 1 == tall
    // > 1 == wide
    // = 1 == square
    // height = width / aspect
    // width = height * aspect

    // if it's tall, make the height the itemSize, and calculate the width;
    // else, make the width the the itemSize and calculate the height;
    const height = aspect < 1 ? itemSize : itemSize / aspect;
    const width = aspect < 1 ? itemSize * aspect : itemSize;
    return { height, width };
  };

  const [flipped, flip] = useState(false);
  const flipProps = useSpring({
    transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
  });
  return (
    <Painting
      className="painting"
      size={gallerySize}
      box={getBoxSize()}
      onMouseEnter={() => {
        flip(true);
      }}
      onMouseLeave={() => {
        flip(false);
      }}
    >
      <FlippyLink
        className="painting__link"
        to={`/painting/${slug.current}`}
        style={flipProps}
      >
        <Image className="painting__image" image={images[0]} />
        <div className="painting__box">
          <h2 className="painting__title">{title}</h2>
          <span className="painting__info">{info}</span>
          <span className="painting__view">
            View <BsArrowRight />
          </span>
        </div>
      </FlippyLink>
    </Painting>
  );
};

const FlippyLink = animated(Link);

const Painting = styled.li`
  flex: 0 0 50%;
  flex: 0 0 ${({ size }) => size.width * 0.5 - 10}px;
  margin-left: 5px;
  margin-right: 5px;
  perspective: 1000px;
  height: ${({ size }) => size.width * 0.5 - 10}px;
  ${media.break`
      flex: 0 0 33.33%;
      flex: 0 0 ${({ size }) => size.width * 0.33333 - 30}px;
      margin-right: 15px; 
      margin-left: 15px;
      height: ${({ size }) => size.width * 0.33333 - 30}px;
    `}
  .painting {
    &__link {
      display: block;
      max-height: 100%;
      position: relative;
      height: 100%;
      width: 100%;
      /* transition: transform 0.8s; */
      transform-style: preserve-3d;
      /* &:hover {
        transform: rotateY(180deg);
      } */
    }
    &__image {
      height: ${({ size }) => size.width * 0.5 - 10}px;
      ${media.break`
        height: ${({ size }) => size.width * 0.333 - 30}px;
      `}
      backface-visibility: hidden;
    }
    &__box {
      background: ${({ theme }) => theme.black};
      color: white;
      padding: 2rem;
      position: absolute;
      width: ${({ box }) => box.width}px;
      height: ${({ box }) => box.height}px;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      transform: translate(-50%, -50%) rotateY(180deg);
      backface-visibility: hidden;
    }
    &__title {
      font-size: 2rem;
    }
    &__info,
    &__view {
      display: block;

      font-size: 1.4rem;
      font-family: ${({ theme }) => theme.font.mono};
    }
    &__view {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      svg {
        margin-left: 0.25rem;
      }
    }
  }
`;

export default GalleryPainting;
