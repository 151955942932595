import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '@sanity/client';

const client = new sanityClient({
  projectId: 'lk92ja6l',
  dataset: 'production',
  token:
    'skuZvUW5cNXJmtg0CYK3rafBx547nTTKuwpsJ616k5lzgMzvAVeygRwvCAbZvelto1sovtj7SpM0WR4Lf9kfQuXJYeOVmMl0DXFCnoXp9PEkWLU6fBSQen0zrZQcGEEuoVtGIn8o48uOWnD1VIC5duHrk37wPNK7ZzDEZShBRUwFMmD3m4Qi',
});
const builder = imageUrlBuilder(client);
function imgUrl(source) {
  return builder.image(source);
}

const roundToNearest = (num, multiple) => {
  return Math.ceil(num / multiple) * multiple;
};

export { imgUrl, roundToNearest };
