import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSpring, useTransition, animated } from 'react-spring';

// import { media } from './theme';
import { imgUrl, roundToNearest } from './utils';
import { useHasEntered, useViewport } from './hooks';

const Image = ({ className, image, onLoad = false }) => {
  const base64 = image.asset.metadata.lqip;
  const [src, setSrc] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [switched, setSwitched] = useState(false);
  const { width: containerWidth } = useViewport();

  useLayoutEffect(() => {
    if (containerWidth) {
      const width = roundToNearest(containerWidth, 50);
      const url = imgUrl(image).width(width).url();
      setSrc(url);
    }
  }, [containerWidth, image]);

  const ref = useRef();
  const hasEntered = useHasEntered(ref);

  useEffect(() => {
    if (loaded && hasEntered) {
      setSwitched(true);
    }
  }, [loaded, hasEntered]);

  const imageSpring = useSpring({
    opacity: switched ? 1 : 0,
  });

  const imagePlaceholderTransition = useTransition(switched, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <CDHQImage
      className={classNames('cdhq-image', className)}
      width={containerWidth}
      ref={ref}
    >
      <animated.img
        className="cdhq-image__image"
        src={src}
        alt={image.alt}
        onLoad={() => {
          setLoaded(true);
          if (onLoad) {
            onLoad();
          }
        }}
        style={imageSpring}
      />
      {imagePlaceholderTransition.map(
        ({ item, key, props }) =>
          !item && (
            <animated.img
              key={key}
              className="cdhq-image__placeholder"
              src={base64}
              alt={image.alt}
              style={props}
            />
          )
      )}
    </CDHQImage>
  );
};

const CDHQImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .cdhq-image {
    &__image {
      /* position: absolute; */
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__placeholder {
      object-fit: contain;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export default Image;
