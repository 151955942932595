import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import Main from './Main';
import { media } from './theme';
import { useElementSize } from './hooks';
import GalleryPainting from './GalleryPainting';
import Loader from './Loader';
import Content from './Content';

const CategoryArchive = props => {
  const { title, paintings, _rawDescription, rest } = props;
  const [galleryRef, gallerySize] = useElementSize();
  const [loading, setLoading] = useState(false);
  const mainRef = useRef();
  const [page, setPage] = useState(0);
  const numPages = Math.ceil(rest.length / 9);

  const [loadedPaintings, setLoadedPaintings] = useState([]);

  useEffect(() => {
    function loadPaintingInfo() {
      const newPaintings = rest.slice(page * 9, (page + 1) * 9);
      setLoadedPaintings([...loadedPaintings, ...newPaintings]);
      setLoading(false);
      setPage(page + 1);
    }
    function testScroll() {
      const { scrollTop, scrollHeight, clientHeight } = mainRef.current;
      if (page <= numPages) {
        if (scrollHeight - clientHeight - scrollTop <= 10) {
          setLoading(true);
          loadPaintingInfo();
        }
      }
    }
    if (mainRef.current) {
      mainRef.current.addEventListener('scroll', testScroll);
    }

    return () => {
      mainRef.current.removeEventListener('scroll', testScroll);
    };
  }, [mainRef, page]);

  return (
    <CategoryMain className="category-main" ref={mainRef}>
      <h1 className="painting-title">{title}</h1>
      <Content>{_rawDescription}</Content>
      <PaintingGallery
        className="painting-gallery"
        ref={galleryRef}
        size={gallerySize}
      >
        {paintings.map(props => (
          <GalleryPainting
            key={props.id}
            {...props}
            gallerySize={gallerySize}
          />
        ))}
        {loadedPaintings.map(props => (
          <GalleryPainting
            key={props.id}
            {...props}
            gallerySize={gallerySize}
          />
        ))}
        {loading && <Loader />}
      </PaintingGallery>
    </CategoryMain>
  );
};

const PaintingGallery = styled.ul`
  list-style: none;
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: ${({ size }) => size.width * 0.5}px;
    grid-gap: 1rem;
    .painting {
      margin: 0;
    }
    ${media.break`
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: ${({ size }) => size.width * 0.33333}px;
      grid-gap: 3rem;
    `}
  }
`;

const CategoryMain = styled(Main)`
  overflow: scroll;
  ${media.break`
    grid-template-rows: auto 1fr;
  `}
`;

export default CategoryArchive;
